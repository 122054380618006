<template>
  <div class="fgg-container bg-accent-500">
    <text-field
      v-model="term"
      label="Name / E-Mail"
      @action="search()"
    ></text-field>
    <action-button color="secondary" @action="search()" label="Suchen" />
  </div>
  <div class="fgg-container">
    <div v-if="loading" class="text-center">
      <font-awesome-icon
        icon="circle-notch"
        spin
        size="2x"
        class="mt-10"
        v-if="loading"
      ></font-awesome-icon>
    </div>
    <div v-else-if="results.length > 0">
      <div
        class="customer"
        v-for="customer in results"
        :key="customer.id"
        @click="openCustomer(customer)"
      >
        <div class="flex">
          <div class="flex-1">
            <div class="name">
              {{ customer.first_name }} {{ customer.last_name }}
            </div>
            <div class="email">
              {{ customer.email }}
            </div>
          </div>
          <div class="flex-initial pt-3">
            <button class="btn btn-icon">
              <font-awesome-icon
                icon="id-card-alt"
                color="green"
                v-if="customer.active_profile"
              ></font-awesome-icon>
              <font-awesome-icon
                icon="id-card-alt"
                color="grey"
                class="opacity-50"
                v-else
              ></font-awesome-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Keine Resultate</p>
    </div>
  </div>
</template>

<script>
import TextField from "../components/form/TextField";
import ActionButton from "../components/form/ActionButton";
import CustomerService from "../services/customerService";
import { useToast, POSITION } from "vue-toastification";
import { useRouter } from "vue-router";
import { mapState, mapMutations } from "vuex";
import useVuelidate from "@vuelidate/core";

export default {
  name: "Search",

  setup() {
    return { v$: useVuelidate(), toast$: useToast(), router: useRouter() };
  },

  components: { TextField, ActionButton },

  data: () => ({
    loading: false,
    term: null,
  }),

  computed: {
    ...mapState("search", ["results"]),
  },

  methods: {
    ...mapMutations("search", ["setResults"]),
    ...mapMutations(["setCustomer", "setProfile", "setCard"]),

    /**
     * Search for customers.
     */
    search() {
      this.loading = true;
      const customerService = new CustomerService();
      customerService
        .search(this.term)
        .then((customers) => {
          this.setResults(customers);
          this.loading = false;
          this.term = null;
        })
        .catch(() => {
          this.toast$.error("Es gab einen Fehler bei der Suche.", {
            position: POSITION.BOTTOM_CENTER,
          });
        });
    },

    /**
     * Open customer.
     *
     * @param customer
     */
    openCustomer(customer) {
      this.setProfile(customer.active_profile);
      this.setCard(customer.card);
      let c = { ...customer };
      delete c.card;
      delete c.active_profile;
      this.setCustomer(c);
      this.router.push(`/customer`);
    },
  },
};
</script>

<style lang="scss" scoped>
.customer {
  @apply bg-accent-500;
  @apply px-3;
  @apply py-2;
  @apply rounded-md;
  @apply mb-3;

  &:last-child {
    @apply mb-0;
  }

  .name {
    @apply font-bold;
    @apply text-lg;
  }

  .email {
    @apply text-base;
  }
}
</style>
