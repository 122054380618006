import axios from "axios";
import { useCookies } from "vue3-cookies";

class customerService {
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_URL;
    const { cookies } = useCookies();
    this.cookies = cookies;
  }

  /**
   * Search for customers.
   *
   * @param term
   * @returns {Promise<null|any>}
   */
  async search(term) {
    try {
      const authToken = this.cookies.get("access_token");
      const response = await axios.get(
        this.baseUrl +
          "/scanner/visitors?_active_profiles&_search=" +
          term +
          "&_sort=first_name",
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      throw new Error("Es gab einen Fehler auf dem Server.");
    }
  }

  /**
   * Fetch customer.
   *
   * @param id
   * @returns {Promise<null|any>}
   */
  async getCustomer(id) {
    try {
      const authToken = this.cookies.get("access_token");
      const response = await axios.get(
        this.baseUrl + "/scanner/visitors/" + id + "?_active_profiles",
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      throw new Error("Es gab einen Fehler auf dem Server.");
    }
  }
}

export default customerService;
